// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    accomplishmentsContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(56), React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(250), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)) ]),
    scrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(32), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    checkIconC: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRadius(16), React_Basic_Native_Styles.borderWidth(2), React_Basic_Native_Styles.borderColor(ZAP_Dart_Client_Styles_Colors.altBg), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(32), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(32), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.secondary) ]),
    accomplishmentRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    header: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(16) ]),
    message: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.lineHeight(18) ]),
    bottomContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(48), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    accomplishmentTxtC: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginLeft(React_Basic_Native_Styles.intMarginLeft)(16), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(6) ]),
    spacer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(16), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(32), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    line: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderWidth(1), React_Basic_Native_Styles.borderColor(ZAP_Dart_Client_Styles_Colors.altBg), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ])
};
var component = React_Basic.createComponent("CompletionProfileStatus");
var completionProfileStatus = (function () {
    var renderAccomplishments = (function () {
        var spacerWithLine = React_Basic_Native_Generated.view()({
            style: sheet.spacer,
            children: [ React_Basic_Native_Generated.view()({
                style: sheet.line,
                children: [  ]
            }) ]
        });
        var mapI = Data_Function.flip(Data_Array.mapWithIndex);
        var accomplishments = [ "Register mobile number", "Earn points", "Complete profile", "You can now browse and purchase rewards" ];
        var isLast = function (index) {
            return !((index + 1 | 0) === Data_Array.length(accomplishments));
        };
        return mapI(accomplishments)(function (i) {
            return function (acc) {
                return React_Basic.fragment([ React_Basic_Native_Generated.view()({
                    style: sheet.accomplishmentRow,
                    children: [ React_Basic_Native_Generated.view_([ React_Basic_Native_Generated.view()({
                        style: sheet.checkIconC,
                        children: [ ZAP_Dart_Client_Components_Icon.icon({
                            name: "check",
                            size: 32,
                            color: ZAP_Dart_Client_Styles_Colors.altBg
                        }) ]
                    }), Data_Monoid.guard(React_Basic.monoidJSX)(isLast(i))(spacerWithLine) ]), React_Basic_Native_Generated.view()({
                        style: sheet.accomplishmentTxtC,
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_Typography.b2({
                            value: acc,
                            color: ZAP_Dart_Client_Styles_Colors.altBg,
                            style: ZAP_Dart_Client_Styles_Utils.noStyle
                        }))
                    }) ]
                }) ]);
            };
        });
    })();
    var render = function (props) {
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ React_Basic_Native_Generated.scrollView()({
                contentContainerStyle: sheet.scrollContainer,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.headerContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.h3({
                        style: sheet.header,
                        value: "CONGRATULATIONS!",
                        color: ZAP_Dart_Client_Styles_Colors.altBg
                    }) ]
                }), ZAP_Dart_Client_Components_Typography.b2({
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    style: sheet.message,
                    value: "You\u2019ve completed all the steps and\x0d unlocked all of the features"
                }), React_Basic_Native_Generated.view()({
                    style: sheet.bottomContainer,
                    children: [ React_Basic_Native_Generated.view()({
                        style: sheet.accomplishmentsContainer,
                        children: renderAccomplishments
                    }), ZAP_Dart_Client_Components_Button.primaryButton({
                        title: "VIEW REWARDS",
                        onPress: props.onAction(new ReactNavigation_Types.PgSubmit(Data_Unit.unit)),
                        disabled: false,
                        inverted: true,
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        size: ZAP_Dart_Client_Components_Button.lg
                    }) ]
                }) ]
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    completionProfileStatus: completionProfileStatus
};
