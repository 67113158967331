// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_RewardItem = require("../ZAP.Dart.Client.Components.RewardItem/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.altBg) ]),
    contentContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(40), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth, React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    topSectionContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(30), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(55) ]),
    successLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontSize(30), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(20) ]),
    subLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    itemList: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(25), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    buttonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(40), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)) ]),
    rewardItemContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(12), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(12), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(15), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.shadowOpacity(0.2), React_Basic_Native_Styles.shadowRadius(2.0), React_Basic_Native_Styles.shadowOffset({
        height: 2.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(4) ])
};
var component = React_Basic.createComponent("CheckoutSuccess");
var purchaseReceipt = function (info) {
    var willUnmount = function (self) {
        return ZAP_Dart_Client_Screens_Utils.removeClock(self.state.intervalId);
    };
    var listItems = function (props) {
        return function (items) {
            return function (now) {
                var itemFn = function (item) {
                    var cProps = {
                        rewardItem: item,
                        now: now,
                        onMoreDetails: props.onAction(ReactNavigation_Types.PgSubmit.create(new ZAP_Dart_Client_Screens_Types.PRGoToRewardDetails(item.productId, item.productCodeId)))
                    };
                    return React_Basic_Native_Generated.view()({
                        style: sheet.rewardItemContainer,
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(ZAP_Dart_Client_Components_RewardItem.rewardItem(cProps))
                    });
                };
                return Data_Functor.map(Data_Functor.functorArray)(itemFn)(items);
            };
        };
    };
    var render = function (v) {
        return React_Basic_Native_Generated.safeAreaView()({
            style: sheet.container,
            children: [ React_Basic_Native_Generated.scrollView()({
                style: sheet.container,
                contentContainerStyle: sheet.contentContainer,
                children: [ React_Basic_Native_Generated.view()({
                    style: sheet.topSectionContainer,
                    children: [ ZAP_Dart_Client_Components_Typography.h2({
                        style: sheet.successLabel,
                        color: ZAP_Dart_Client_Styles_Colors.altFg,
                        value: "SUCCESS!"
                    }), ZAP_Dart_Client_Components_Typography.b1({
                        style: sheet.subLabel,
                        color: ZAP_Dart_Client_Styles_Colors.altFg,
                        value: "You purchased the following rewards:"
                    }) ]
                }), React_Basic_Native_Generated.view()({
                    style: sheet.itemList,
                    children: listItems(v.props)(info.items)(v.state.now)
                }), React_Basic_Native_Generated.view()({
                    style: sheet.buttonContainer,
                    children: [ ZAP_Dart_Client_Components_Button.primaryButton({
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        title: "GO BACK TO CATALOG",
                        onPress: v.props.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.PRGoBackToCatalog.value)),
                        disabled: false,
                        inverted: false,
                        size: ZAP_Dart_Client_Components_Button.md
                    }) ]
                }) ]
            }) ]
        });
    };
    var initialState = {
        now: Data_Maybe.Nothing.value,
        intervalId: Data_Maybe.Nothing.value
    };
    var didMount = function (self) {
        return function __do() {
            self.props.updateTotalPoints(info.totalPoints)();
            return ZAP_Dart_Client_Screens_Utils.addClock(10.0)(self)();
        };
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        didMount: didMount,
        willUnmount: willUnmount,
        render: render
    });
};
module.exports = {
    purchaseReceipt: purchaseReceipt
};
