// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HugeNum = require("../Data.HugeNum/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_FlatList = require("../ReactNative.FlatList/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_CartItem = require("../ZAP.Dart.Client.Components.CartItem/index.js");
var ZAP_Dart_Client_Components_EmptyPage = require("../ZAP.Dart.Client.Components.EmptyPage/index.js");
var ZAP_Dart_Client_Components_FooterButton = require("../ZAP.Dart.Client.Components.FooterButton/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Dimensions = require("../ZAP.Dart.Client.Styles.Dimensions/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var ZRPC_Clients_Trove = require("../ZRPC.Clients.Trove/index.js");
var NotEnoughStocks = (function () {
    function NotEnoughStocks() {

    };
    NotEnoughStocks.value = new NotEnoughStocks();
    return NotEnoughStocks;
})();
var NotEnoughPoints = (function () {
    function NotEnoughPoints() {

    };
    NotEnoughPoints.value = new NotEnoughPoints();
    return NotEnoughPoints;
})();
var ProfileNotComplete = (function () {
    function ProfileNotComplete(value0) {
        this.value0 = value0;
    };
    ProfileNotComplete.create = function (value0) {
        return new ProfileNotComplete(value0);
    };
    return ProfileNotComplete;
})();
var CannotLoadCart = (function () {
    function CannotLoadCart() {

    };
    CannotLoadCart.value = new CannotLoadCart();
    return CannotLoadCart;
})();
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ]),
    bottomView: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(0), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    headerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(30), React_Basic_Native_Styles.paddingTop(React_Basic_Native_Styles.intPaddingTop)(30), React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(15), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.borderBottomWidth(React_Basic_Native_Styles.hairlineWidth), React_Basic_Native_Styles.borderBottomColor(ZAP_Dart_Client_Styles_Colors.separator) ]),
    headerTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_Styles_Text.left) ]),
    deleteContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)) ]),
    listContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(102), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles_Flex.flexGrow(1), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), ZAP_Dart_Client_Styles_Dimensions.screenMaxWidth ]),
    footerContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.bottom(React_Basic_Native_Styles.intBottom)(0) ]),
    deleteButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(4) ]),
    deleteText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles_Text.fontSize(12), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.2), React_Basic_Native_Styles.paddingLeft(React_Basic_Native_Styles.intPaddingLeft)(4) ]),
    listScrollContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexGrow(1), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ])
};
var component = React_Basic.createComponent("CartMgt");
var cartMgt = function (v) {
    return function (props) {
        var problemAlert = function (title) {
            return new ReactNavigation_Types.Alert({
                title: title,
                message: "Looks like we encountered a problem",
                actionLabel: "Try Again"
            });
        };
        var onUpdate = function (v1) {
            return function (pq) {
                var onResolve = function (res) {
                    if (res instanceof Data_Either.Right) {
                        return function __do() {
                            v1.setState(function (v2) {
                                return {
                                    items: res.value0.cart.items,
                                    markedItems: v2.markedItems
                                };
                            })();
                            return v1.props.updateTotalPoints(res.value0.totalPoints)();
                        };
                    };
                    if (res instanceof Data_Either.Left) {
                        return v1.props.showDialog(problemAlert("Unable to update cart item"))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 127, column 21 - line 134, column 22): " + [ res.constructor.name ]);
                };
                var updateCart = ZAP_Dart_Client_Screens_Utils.mkLApi(v.apiCalls.updateCart({
                    productQuantity: pq
                }))(onResolve);
                return v1.props.loadApiP(updateCart);
            };
        };
        var onMarked = function (v1) {
            return function (item) {
                var v2 = Data_Array.elemIndex(Data_Eq.eqString)(item.productId)(v1.state.markedItems);
                if (v2 instanceof Data_Maybe.Just) {
                    return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (newArr) {
                        return v1.setState(function (v4) {
                            return {
                                items: v4.items,
                                markedItems: newArr
                            };
                        });
                    })(Data_Array.deleteAt(v2.value0)(v1.state.markedItems));
                };
                if (v2 instanceof Data_Maybe.Nothing) {
                    return v1.setState(function (v3) {
                        return {
                            items: v3.items,
                            markedItems: Data_Array.snoc(v1.state.markedItems)(item.productId)
                        };
                    });
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 112, column 51 - line 114, column 89): " + [ v2.constructor.name ]);
            };
        };
        var onCheckout = function (v1) {
            var submitAction = function (isProfComp) {
                return function (items) {
                    return props.onAction(ReactNavigation_Types.PgSubmit.create(Data_Either.Right.create(new Data_Tuple.Tuple(isProfComp, items))));
                };
            };
            var precheck = function (markedItems) {
                return function (res) {
                    var isProfComplete = function (bool) {
                        return function (items) {
                            if (!bool) {
                                return Data_Either.Left.create(new ProfileNotComplete(items));
                            };
                            if (bool) {
                                return new Data_Either.Right(Data_Unit.unit);
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 290, column 35 - line 292, column 27): " + [ bool.constructor.name ]);
                        };
                    };
                    var hasEnoughStocks = function (cartItems) {
                        var checker = function (acc) {
                            return function (cartItem) {
                                return (function () {
                                    if (cartItem.quantity instanceof Data_Maybe.Just && cartItem.availableQuantity instanceof Data_Maybe.Just) {
                                        return cartItem.quantity.value0 <= cartItem.availableQuantity.value0;
                                    };
                                    return true;
                                })() && acc;
                            };
                        };
                        var v2 = Data_Foldable.foldl(Data_Foldable.foldableArray)(checker)(true)(cartItems);
                        if (v2) {
                            return new Data_Either.Right(Data_Unit.unit);
                        };
                        if (!v2) {
                            return new Data_Either.Left(NotEnoughStocks.value);
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 266, column 11 - line 268, column 42): " + [ v2.constructor.name ]);
                    };
                    var hasEnoughPoints = function (cartItems) {
                        return function (totalPoints) {
                            var mTM = function (xM) {
                                return function (yM) {
                                    var v2 = Control_Apply.lift2(Data_Maybe.applyMaybe)(function (x) {
                                        return function (y) {
                                            return Data_Semiring.mul(Data_HugeNum.semiringHugeNum)(x)(Data_HugeNum.fromNumber(Data_Int.toNumber(y)));
                                        };
                                    })(xM)(yM);
                                    if (v2 instanceof Data_Maybe.Just) {
                                        return v2.value0;
                                    };
                                    if (v2 instanceof Data_Maybe.Nothing) {
                                        return Data_HugeNum.fromNumber(0.0);
                                    };
                                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 273, column 23 - line 275, column 46): " + [ v2.constructor.name ]);
                                };
                            };
                            var totalAmount = Data_Foldable.foldl(Data_Foldable.foldableArray)(function (acc) {
                                return function (cartItem) {
                                    return Data_Semiring.add(Data_HugeNum.semiringHugeNum)(acc)(mTM(cartItem.price)(cartItem.quantity));
                                };
                            })(Data_HugeNum.fromNumber(0.0))(cartItems);
                            var v2 = Data_Maybe.fromMaybe(false)(Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(totalPoints)(function (tp) {
                                return Data_Ord.greaterThanOrEq(Data_HugeNum.ordHugeNum)(tp)(totalAmount);
                            }));
                            if (v2) {
                                return new Data_Either.Right(Data_Unit.unit);
                            };
                            if (!v2) {
                                return new Data_Either.Left(NotEnoughPoints.value);
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 285, column 11 - line 287, column 42): " + [ v2.constructor.name ]);
                        };
                    };
                    return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Function["const"](CannotLoadCart.value))(res))(function (v2) {
                        return Control_Bind.bind(Data_Either.bindEither)(Control_Applicative.pure(Data_Either.applicativeEither)(Data_Array.filter(function (item) {
                            return Data_Maybe.isJust(Data_Array.elemIndex(Data_Eq.eqString)(item.productId)(markedItems));
                        })(v2.cart.items)))(function (selectedItems) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(hasEnoughStocks(selectedItems))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(hasEnoughPoints(selectedItems)(v2.totalPoints))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(isProfComplete(v2.isProfileComplete)(selectedItems))(function () {
                                        return Control_Applicative.pure(Data_Either.applicativeEither)(selectedItems);
                                    });
                                });
                            });
                        });
                    });
                };
            };
            var getCart = ZAP_Dart_Client_Screens_Utils.mkLApi(v.apiCalls.getCart)(function (res) {
                var v2 = precheck(v1.state.markedItems)(res);
                if (v2 instanceof Data_Either.Right) {
                    return submitAction(true)(v2.value0);
                };
                if (v2 instanceof Data_Either.Left && v2.value0 instanceof NotEnoughStocks) {
                    return props.showDialog(new ReactNavigation_Types.Alert({
                        title: "Not enough items in stock",
                        message: "Looks like this reward is selling fast",
                        actionLabel: "Go Back to Cart"
                    }))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                };
                if (v2 instanceof Data_Either.Left && v2.value0 instanceof NotEnoughPoints) {
                    return props.showDialog(new ReactNavigation_Types.Alert({
                        title: "Oops! You don\u2019t have enough points",
                        message: "Scan more codes to earn more points",
                        actionLabel: "Go to QR Scanner"
                    }))(props.onAction(ReactNavigation_Types.PgSubmit.create(new Data_Either.Left(Data_Unit.unit))));
                };
                if (v2 instanceof Data_Either.Left && v2.value0 instanceof ProfileNotComplete) {
                    return props.showDialog(new ReactNavigation_Types.Alert({
                        title: "Looks like your profile is still incomplete",
                        message: "Complete your profile to purchase rewards",
                        actionLabel: "Complete Profile"
                    }))(submitAction(false)(v2.value0.value0));
                };
                if (v2 instanceof Data_Either.Left && v2.value0 instanceof CannotLoadCart) {
                    return props.showDialog(problemAlert("Unable to load cart"))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 214, column 19 - line 243, column 101): " + [ v2.constructor.name ]);
            });
            return props.loadApiP(getCart);
        };
        var listItem = function (self) {
            return function (v1) {
                var cProps = {
                    onUpdate: onUpdate(self),
                    onMarked: onMarked(self),
                    cartItem: v1.item,
                    marked: Data_Maybe.isJust(Data_Array.elemIndex(Data_Eq.eqString)(v1.item.productId)(self.state.markedItems))
                };
                return ZAP_Dart_Client_Components_CartItem.cartItem(cProps);
            };
        };
        var isCheckOutDisabled = function (self) {
            return Data_Array["null"](self.state.markedItems);
        };
        var initialState = {
            items: (function () {
                if (props.initD instanceof Data_Either.Right) {
                    return props.initD.value0.cart.items;
                };
                if (props.initD instanceof Data_Either.Left) {
                    return [  ];
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 85, column 7 - line 87, column 23): " + [ props.initD.constructor.name ]);
            })(),
            markedItems: [  ]
        };
        var getSubtotal = function (v1) {
            var filteredItems = Data_Array.filter(function (item) {
                return Data_Maybe.isJust(Data_Array.elemIndex(Data_Eq.eqString)(item.productId)(v1.state.markedItems));
            })(v1.state.items);
            return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_HugeNum.semiringHugeNum)(Data_Functor.mapFlipped(Data_Functor.functorArray)(filteredItems)(function (m) {
                return Data_Semiring.mul(Data_HugeNum.semiringHugeNum)(Data_HugeNum.fromNumber(Data_Int.toNumber(Data_Maybe.fromMaybe(0)(m.quantity))))(Data_Maybe.fromMaybe(Data_HugeNum.fromNumber(0.0))(m.price));
            }));
        };
        var getQuantity = function (v1) {
            var filteredItems = Data_Array.filter(function (item) {
                return Data_Maybe.isJust(Data_Array.elemIndex(Data_Eq.eqString)(item.productId)(v1.state.markedItems));
            })(v1.state.items);
            return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(Data_Functor.mapFlipped(Data_Functor.functorArray)(filteredItems)(function (item) {
                return Data_Maybe.fromMaybe(0)(item.quantity);
            }));
        };
        var footer = function (v1) {
            return ZAP_Dart_Client_Components_FooterButton.footerButton({
                style: sheet.footerContainer,
                onPress: onCheckout(v1),
                title: "Your cart subtotal (" + (Data_Show.show(Data_Show.showInt)(getQuantity(v1)) + " Items)"),
                points: getSubtotal(v1),
                btnText: "CONFIRM CHECKOUT",
                disabled: isCheckOutDisabled(v1)
            });
        };
        var didUpdate = function (self) {
            return function (v1) {
                return Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Either.eqEither(ZAP_Dart_Common.getCartErrEq)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "totalPoints";
                }))(Data_Maybe.eqMaybe(Data_HugeNum.eqHugeNum)))()(new Data_Symbol.IsSymbol(function () {
                    return "isProfileComplete";
                }))(Data_Eq.eqBoolean))()(new Data_Symbol.IsSymbol(function () {
                    return "cart";
                }))(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "items";
                }))(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
                    return "vendorShortName";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "vendorName";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "vendorColoredLogoUri";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "terms";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "stockCountLabel";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "redemptionInstruction";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "quantity";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqInt)))()(new Data_Symbol.IsSymbol(function () {
                    return "productId";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "price";
                }))(Data_Maybe.eqMaybe(Data_HugeNum.eqHugeNum)))()(new Data_Symbol.IsSymbol(function () {
                    return "photoUri";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "name";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "expiry";
                }))(Data_Maybe.eqMaybe(Data_DateTime.eqDateTime)))()(new Data_Symbol.IsSymbol(function () {
                    return "description";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
                    return "codeType";
                }))(ZRPC_Clients_Trove.eqCodeType))()(new Data_Symbol.IsSymbol(function () {
                    return "codeLabel";
                }))(Data_Eq.eqString))()(new Data_Symbol.IsSymbol(function () {
                    return "availableQuantity";
                }))(Data_Maybe.eqMaybe(Data_Eq.eqInt))))))))))(self.props.initD)(v1.prevProps.initD))(self.setState(function (state) {
                    if (self.props.initD instanceof Data_Either.Right) {
                        return {
                            items: self.props.initD.value0.cart.items,
                            markedItems: Data_Array.filter(function (productId) {
                                return Data_Maybe.isJust(Data_Foldable.find(Data_Foldable.foldableArray)(function (c) {
                                    return productId === c.productId;
                                })(self.props.initD.value0.cart.items));
                            })(state.markedItems)
                        };
                    };
                    if (self.props.initD instanceof Data_Either.Left) {
                        return state;
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 98, column 23 - line 108, column 32): " + [ self.props.initD.constructor.name ]);
                }));
            };
        };
        var didMount = function (self) {
            if (self.props.initD instanceof Data_Either.Right) {
                return self.props.updateTotalPoints(self.props.initD.value0.totalPoints);
            };
            if (self.props.initD instanceof Data_Either.Left) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 91, column 19 - line 93, column 24): " + [ self.props.initD.constructor.name ]);
        };
        var $$delete = function (v1) {
            var onResolve = function (res) {
                if (res instanceof Data_Either.Right) {
                    return function __do() {
                        v1.setState(function (v2) {
                            return {
                                items: res.value0.cart.items,
                                markedItems: [  ]
                            };
                        })();
                        return v1.props.updateTotalPoints(res.value0.totalPoints)();
                    };
                };
                if (res instanceof Data_Either.Left) {
                    return v1.props.showDialog(problemAlert("Unable to delete cart items"))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                };
                throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.CartMgt (line 147, column 21 - line 158, column 22): " + [ res.constructor.name ]);
            };
            var deleteFromCart = ZAP_Dart_Client_Screens_Utils.mkLApi(v.apiCalls.deleteFromCart({
                productIds: v1.state.markedItems
            }))(onResolve);
            return v1.props.loadApiP(deleteFromCart);
        };
        var onDelete = function (self) {
            return self.props.showDialog(new ReactNavigation_Types.Confirm({
                title: "Delete items?",
                message: "Are you sure you want to remove these items from your cart?",
                primaryActionLabel: "DELETE",
                secondaryActionLabel: Data_Maybe.Nothing.value
            }))($$delete(self));
        };
        var button = function (v1) {
            return React_Basic_Native_Generated.touchableOpacity()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.deleteButton, ZAP_Dart_Client_Styles_Utils.styleDisabled(Data_Array["null"](v1.state.markedItems)) ]),
                disabled: Data_Array["null"](v1.state.markedItems),
                onPress: React_Basic_Events.handler_(onDelete(v1)),
                children: [ ZAP_Dart_Client_Components_Icon.icon({
                    name: "trash_filled",
                    size: 17,
                    color: ZAP_Dart_Client_Styles_Colors.error
                }), React_Basic_Native_Generated.text()({
                    style: React_Basic_Native_Styles["styles'"]([ sheet.deleteText, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.error) ]) ]),
                    children: [ React_Basic_Native.string("DELETE") ]
                }) ]
            });
        };
        var header = function (v1) {
            return React_Basic_Native_Generated.view()({
                style: sheet.headerContainer,
                children: [ ZAP_Dart_Client_Components_Typography.h3({
                    style: sheet.headerTitle,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: "My Cart"
                }), React_Basic_Native_Generated.view()({
                    style: sheet.deleteContainer,
                    children: [ button(v1) ]
                }) ]
            });
        };
        var list = function (self) {
            return React_Basic_Native_Generated.view()({
                style: sheet.listContainer,
                children: [ ReactNative_FlatList.flatList()({
                    data: self.state.items,
                    renderItem: listItem(self),
                    keyExtractor: function (item, i) {
                        return item.productId;
                    },
                    ListHeaderComponent: header(self),
                    ListEmptyComponent: ZAP_Dart_Client_Components_EmptyPage.emptyPage({
                        image: ZAP_Dart_Client_Assets.emptyCart,
                        message: {
                            primary: "Your cart is empty",
                            secondary: "Browse the catalog to start adding rewards to your cart"
                        }
                    })
                }) ]
            });
        };
        var render = function (self) {
            return React_Basic.fragment([ React_Basic_Native_Generated.safeAreaView()({
                style: sheet.container,
                children: [ React_Basic_Native_Generated.scrollView()({
                    contentContainerStyle: sheet.listScrollContainer,
                    children: [ list(self) ]
                }), footer(self) ]
            }), React_Basic_Native_Generated.safeAreaView()({
                style: sheet.bottomView
            }) ]);
        };
        return React_Basic.make()(component)({
            initialState: initialState,
            didMount: didMount,
            didUpdate: didUpdate,
            render: render
        })(props);
    };
};
module.exports = {
    cartMgt: cartMgt
};
