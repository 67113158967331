// Generated by purs version 0.13.8
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Divider = require("../ZAP.Dart.Client.Components.Divider/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    midRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(12), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(20) ]),
    bottomRow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch) ]),
    titleText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(20), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.lineHeight(24), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(2), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold) ]),
    messageText: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(16), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.5), React_Basic_Native_Styles_Text.lineHeight(18), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    divider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(16), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch) ]),
    verticalDivider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(1), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(80)), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.divider) ]),
    actionBtnsContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(53) ]),
    actionBtn: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)) ]),
    primaryActionLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(16), React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.lineHeight(21), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.8), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ]),
    secondaryActionLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(16), React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.lineHeight(21), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.8), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.fg), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular) ])
};
var confirmDialog = function (v) {
    return React_Basic.fragment([ React_Basic_Native_Generated.view()({
        style: sheet.midRow,
        children: Data_Array.singleton(React_Basic_Native_Generated.text()({
            style: sheet.titleText,
            children: [ React_Basic_Native.string(v.info.title) ]
        }))
    }), React_Basic_Native_Generated.view()({
        style: sheet.midRow,
        children: Data_Array.singleton(React_Basic_Native_Generated.text()({
            style: sheet.messageText,
            children: [ React_Basic_Native.string(v.info.message) ]
        }))
    }), ZAP_Dart_Client_Components_Divider.divider(sheet.divider), React_Basic_Native_Generated.view()({
        style: sheet.actionBtnsContainer,
        children: [ React_Basic_Native_Generated.touchableOpacity()({
            style: sheet.actionBtn,
            onPress: React_Basic_Events.handler_(v.onAction(new ReactNavigation_Types.DgSubmit(false))),
            children: [ React_Basic_Native_Generated.text()({
                style: sheet.secondaryActionLabel,
                children: [ React_Basic_Native.string(Data_Maybe.fromMaybe("CANCEL")(v.info.secondaryActionLabel)) ]
            }) ]
        }), React_Basic_Native_Generated.view()({
            style: sheet.verticalDivider
        }), React_Basic_Native_Generated.touchableOpacity()({
            style: sheet.actionBtn,
            onPress: React_Basic_Events.handler_(v.onAction(new ReactNavigation_Types.DgSubmit(true))),
            children: [ React_Basic_Native_Generated.text()({
                style: sheet.primaryActionLabel,
                children: [ React_Basic_Native.string(v.info.primaryActionLabel) ]
            }) ]
        }) ]
    }) ]);
};
module.exports = {
    confirmDialog: confirmDialog,
    sheet: sheet
};
