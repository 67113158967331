// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_DashedInput = require("../ZAP.Dart.Client.Components.DashedInput/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Templates_StepWrapper = require("../ZAP.Dart.Client.Screens.Templates.StepWrapper/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    resetPinLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(64) ]),
    resetPinBtn: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(4) ]),
    errorMsg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(18) ])
};
var component = React_Basic.createComponent("EnterPin");
var enterPin = function (message) {
    return function (v) {
        var verifyPin = function (self) {
            return function (pin) {
                var onAction = function (r) {
                    return self.props.onAction(ReactNavigation_Types.PgSubmit.create(r));
                };
                var onSuccess = function (v1) {
                    if (v1 instanceof Data_Either.Right) {
                        return onAction(Data_Either.Right.create(new ZAP_Dart_Client_Screens_Types.EPAReturnVerifiedPin(v1.value0)));
                    };
                    if (v1 instanceof Data_Either.Left) {
                        if (v1.value0 instanceof ZAP_Dart_Client_Screens_Types.PinErrInvalidPin) {
                            return self.setState(function (v2) {
                                return {
                                    showPinErr: true,
                                    pin: v2.pin
                                };
                            });
                        };
                        if (v1.value0 instanceof ZAP_Dart_Client_Screens_Types.PinErrAccountLocked) {
                            return onAction(new Data_Either.Left({
                                title: "Verification Failed",
                                message: "Account locked"
                            }));
                        };
                        if (v1.value0 instanceof ZAP_Dart_Client_Screens_Types.PinErrOthers) {
                            return onAction(new Data_Either.Left({
                                title: "Verification Failed",
                                message: "Try Again"
                            }));
                        };
                        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EnterPin (line 51, column 21 - line 57, column 85): " + [ v1.value0.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.EnterPin (line 49, column 21 - line 57, column 85): " + [ v1.constructor.name ]);
                };
                var aff = v.apiCalls.verifyPin(pin);
                return self.props.loadApiP(ZAP_Dart_Client_Screens_Utils.mkLApi(aff)(onSuccess));
            };
        };
        var submit = function (self) {
            return function (pin) {
                return Control_Applicative.when(Effect.applicativeEffect)(Data_String_CodeUnits.length(pin) === 6)(Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v1) {
                    return {
                        pin: "",
                        showPinErr: v1.showPinErr
                    };
                }))(verifyPin(self)(pin)));
            };
        };
        var onSubmit = function (self) {
            return React_Basic_Events.handler_(submit(self)(self.state.pin));
        };
        var render = function (self) {
            return ZAP_Dart_Client_Screens_Templates_StepWrapper.stepWrapper({
                header: new Data_Tuple.Tuple("ENTER YOUR", "6-DIGIT PIN"),
                message: message,
                content: [ ZAP_Dart_Client_Components_DashedInput.dashedInput({
                    value: self.state.pin,
                    length: 6,
                    fontSize: 32,
                    letterSpacing: 8,
                    onChange: function (text) {
                        return Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v1) {
                            return {
                                pin: text,
                                showPinErr: false
                            };
                        }))(submit(self)(text));
                    },
                    inputType: ZAP_Dart_Client_Components_DashedInput.Numeric.value,
                    onSubmitEditing: onSubmit(self),
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    style: sheet.input,
                    secureTextEntry: true,
                    autoFocus: true
                }), ZAP_Dart_Client_Components_Typography.b2({
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    style: sheet.errorMsg,
                    value: Data_Monoid.guard(Data_Monoid.monoidString)(self.state.showPinErr)("The PIN you entered is invalid!")
                }), ZAP_Dart_Client_Components_Typography.b2({
                    color: ZAP_Dart_Client_Styles_Colors.altBg,
                    style: sheet.resetPinLabel,
                    value: "Forgot your PIN?"
                }), ZAP_Dart_Client_Components_Button.linkButton({
                    onPress: self.props.onAction(ReactNavigation_Types.PgSubmit.create(new Data_Either.Right(ZAP_Dart_Client_Screens_Types.EPAResetPin.value))),
                    title: "Reset PIN",
                    size: ZAP_Dart_Client_Components_Button.sm,
                    style: sheet.resetPinBtn,
                    disabled: false
                }) ]
            });
        };
        var initialState = {
            pin: "",
            showPinErr: false
        };
        return React_Basic.make()(component)({
            initialState: initialState,
            render: render
        });
    };
};
module.exports = {
    enterPin: enterPin
};
