// Generated by purs version 0.13.8
"use strict";
var ubuntuMono = {
    regular: "UbuntuMono-Regular",
    italic: "UbuntuMono-Italic",
    bold: "UbuntuMono-Bold"
};
var proximaNova = {
    regular: "ProximaNova-Regular",
    italic: "ProximaNova-RegularIt",
    bold: "ProximaNova-Bold",
    semiBold: "ProximaNova-Semibold",
    extraBold: "ProximaNova-Extrabld",
    light: "ProximaNova-Light"
};
module.exports = {
    proximaNova: proximaNova,
    ubuntuMono: ubuntuMono
};
