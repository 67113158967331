// Generated by purs version 0.13.8
"use strict";
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var XSmall = (function () {
    function XSmall() {

    };
    XSmall.value = new XSmall();
    return XSmall;
})();
var Small = (function () {
    function Small() {

    };
    Small.value = new Small();
    return Small;
})();
var Medium = (function () {
    function Medium() {

    };
    Medium.value = new Medium();
    return Medium;
})();
var Large = (function () {
    function Large() {

    };
    Large.value = new Large();
    return Large;
})();
var xs = XSmall.value;
var styleDisabled = function (disabled) {
    return React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.opacity((function () {
        if (disabled) {
            return 0.4;
        };
        if (!disabled) {
            return 1.0;
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Button (line 81, column 17 - line 83, column 21): " + [ disabled.constructor.name ]);
    })()) ]);
};
var sm = Small.value;
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(4), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_Styles_Flex.stretch) ]),
    title: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    smallContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(22), React_Basic_Native_Styles.borderRadius(5), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(2) ]),
    mediumContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(42), React_Basic_Native_Styles.borderRadius(10), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8) ]),
    largeContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(45), React_Basic_Native_Styles.borderRadius(10), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(12) ]),
    xsmallTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(10), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(1) ]),
    smallTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(14), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.4) ]),
    mediumTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(1.8) ]),
    largeTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(20), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(2) ]),
    secondaryButtonContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    secondaryButtonTitle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(16), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold) ])
};
var titleStyleFromSize = function (v) {
    if (v instanceof XSmall) {
        return sheet.xsmallTitle;
    };
    if (v instanceof Small) {
        return sheet.smallTitle;
    };
    if (v instanceof Medium) {
        return sheet.mediumTitle;
    };
    if (v instanceof Large) {
        return sheet.largeTitle;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Button (line 186, column 22 - line 190, column 28): " + [ v.constructor.name ]);
};
var md = Medium.value;
var lg = Large.value;
var containerStyleFromSize = function (v) {
    if (v instanceof XSmall) {
        return sheet.smallContainer;
    };
    if (v instanceof Small) {
        return sheet.smallContainer;
    };
    if (v instanceof Medium) {
        return sheet.mediumContainer;
    };
    if (v instanceof Large) {
        return sheet.largeContainer;
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Button (line 179, column 26 - line 183, column 32): " + [ v.constructor.name ]);
};
var component = React_Basic.createComponent("Button");
var button = (function () {
    var render = function (props) {
        return React_Basic_Native_Generated.touchableOpacity()({
            style: React_Basic_Native_Styles["styles'"]([ sheet.container, styleDisabled(props.disabled), containerStyleFromSize(props.size), React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.backgroundColor(props.backgroundColor) ]), props.containerStyle ]),
            disabled: props.disabled,
            onPress: React_Basic_Events.handler_(props.onPress),
            children: [ React_Basic_Native_Generated.text()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.title, styleDisabled(props.disabled), titleStyleFromSize(props.size), props.titleStyle, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles_Text.color(props.color) ]) ]),
                children: [ React_Basic_Native.string(props.title) ]
            }) ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
var linkButton = function (v) {
    return button({
        title: v.title,
        onPress: v.onPress,
        disabled: v.disabled,
        containerStyle: v.style,
        titleStyle: ZAP_Dart_Client_Styles_Utils.noStyle,
        size: v.size,
        color: ZAP_Dart_Client_Styles_Colors.primary,
        backgroundColor: React_Basic_Native_PropTypes_Color.transparent
    });
};
var primaryButton = function (props) {
    return button({
        title: props.title,
        onPress: props.onPress,
        disabled: props.disabled,
        containerStyle: props.style,
        titleStyle: ZAP_Dart_Client_Styles_Utils.noStyle,
        size: props.size,
        color: (function () {
            if (props.inverted) {
                return ZAP_Dart_Client_Styles_Colors.primary;
            };
            if (!props.inverted) {
                return ZAP_Dart_Client_Styles_Colors.bg;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Button (line 104, column 14 - line 106, column 27): " + [ props.inverted.constructor.name ]);
        })(),
        backgroundColor: (function () {
            if (props.inverted) {
                return ZAP_Dart_Client_Styles_Colors.bg;
            };
            if (!props.inverted) {
                return ZAP_Dart_Client_Styles_Colors.primary;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Button (line 107, column 24 - line 109, column 32): " + [ props.inverted.constructor.name ]);
        })()
    });
};
var secondaryButton = function (props) {
    return button({
        title: props.title,
        onPress: props.onPress,
        disabled: props.disabled,
        containerStyle: React_Basic_Native_Styles["styles'"]([ sheet.secondaryButtonContainer, props.style ]),
        titleStyle: sheet.secondaryButtonTitle,
        size: sm,
        color: (function () {
            if (props.inverted) {
                return ZAP_Dart_Client_Styles_Colors.bg;
            };
            if (!props.inverted) {
                return ZAP_Dart_Client_Styles_Colors.primary;
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Button (line 132, column 14 - line 134, column 32): " + [ props.inverted.constructor.name ]);
        })(),
        backgroundColor: React_Basic_Native_PropTypes_Color.transparent
    });
};
module.exports = {
    XSmall: XSmall,
    Small: Small,
    Medium: Medium,
    Large: Large,
    button: button,
    primaryButton: primaryButton,
    secondaryButton: secondaryButton,
    linkButton: linkButton,
    xs: xs,
    sm: sm,
    md: md,
    lg: lg
};
