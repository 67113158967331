// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_StatusBar = require("../ReactNative.StatusBar/index.js");
var ReactNative_Touchables = require("../ReactNative.Touchables/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_BuildConfig = require("../ZAP.Dart.Client.BuildConfig/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_PrivacyLinks = require("../ZAP.Dart.Client.Components.PrivacyLinks/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Contexts_AdvancedOpts = require("../ZAP.Dart.Client.Contexts.AdvancedOpts/index.js");
var ZAP_Dart_Client_Contexts_Global = require("../ZAP.Dart.Client.Contexts.Global/index.js");
var ZAP_Dart_Client_Screens_Utils = require("../ZAP.Dart.Client.Screens.Utils/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Styles_Utils = require("../ZAP.Dart.Client.Styles.Utils/index.js");
var privacyLinksFg = React_Basic_Native_PropTypes_Color.rgb(159)(159)(159);
var privacyLinksBg = React_Basic_Native_PropTypes_Color.rgb(241)(241)(241);
var sheet = {
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(60), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg) ]),
    shadow: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.shadowOpacity(0.1), React_Basic_Native_Styles.shadowRadius(3.0), React_Basic_Native_Styles.shadowOffset({
        height: 4.0,
        width: 0.0
    }), React_Basic_Native_Styles.shadowColor("black"), React_Basic_Native_Styles.elevation(2) ]),
    backButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.left(React_Basic_Native_Styles.intLeft)(10) ]),
    background: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(privacyLinksBg) ]),
    logo: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(86), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(56), React_Basic_Native_Styles.resizeMode(React_Basic_Native_Styles.contain) ]),
    totalPointsBanner: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(38), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(15), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.primary), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.spaceBetween), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    totalPoints: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.bold), React_Basic_Native_Styles.paddingLeft(React_Basic_Native_Styles.intPaddingLeft)(20) ]),
    titleBanner: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(38), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(15), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.secondary), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    privacyLinksContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(privacyLinksBg), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(2), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8) ]),
    privacyLinkDivider: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRightWidth(1), React_Basic_Native_Styles.borderColor(privacyLinksFg), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(3) ]),
    privacyLinkContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(8) ]),
    privacyLink: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.color(privacyLinksFg), React_Basic_Native_Styles_Text.fontSize(12), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold) ]),
    privacyLinkIcon: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(10), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(10), React_Basic_Native_Styles.marginLeft(React_Basic_Native_Styles.intMarginLeft)(4) ])
};
var component = React_Basic.createComponent("BaseNavBar");
var baseNavBar = function (opts) {
    var render = function (v) {
        var logo = ZAP_Dart_Client_Contexts_AdvancedOpts.activator(React_Basic_Native_Generated.image()({
            style: sheet.logo,
            source: ZAP_Dart_Client_BuildConfig.buildConfig.coloredLogo
        }));
        var banner = function (totalPoints) {
            if (v.banner instanceof ReactNavigation_Types.NBTotalPoints) {
                return React_Basic_Native_Generated.view()({
                    style: React_Basic_Native_Styles["styles'"]([ sheet.totalPointsBanner ]),
                    children: [ ZAP_Dart_Client_Components_Typography.b2({
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        color: ZAP_Dart_Client_Styles_Colors.bg,
                        value: "Your points"
                    }), ZAP_Dart_Client_Components_Typography.b2({
                        style: sheet.totalPoints,
                        color: ZAP_Dart_Client_Styles_Colors.bg,
                        value: (function () {
                            if (totalPoints instanceof Data_Maybe.Just) {
                                return ZAP_Dart_Client_Screens_Utils.formatPoints(totalPoints.value0) + " PTS";
                            };
                            if (totalPoints instanceof Data_Maybe.Nothing) {
                                return "-- PTS";
                            };
                            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.NavBar.BaseNavBar (line 119, column 19 - line 121, column 40): " + [ totalPoints.constructor.name ]);
                        })()
                    }) ]
                });
            };
            if (v.banner instanceof ReactNavigation_Types.NBTitle) {
                return React_Basic_Native_Generated.view()({
                    style: sheet.titleBanner,
                    children: [ ZAP_Dart_Client_Components_Typography.b2({
                        style: ZAP_Dart_Client_Styles_Utils.noStyle,
                        color: ZAP_Dart_Client_Styles_Colors.bg,
                        value: v.banner.value0
                    }) ]
                });
            };
            if (v.banner instanceof ReactNavigation_Types.NBNone) {
                return Data_Monoid.mempty(React_Basic.monoidJSX);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Components.NavBar.BaseNavBar (line 102, column 26 - line 136, column 23): " + [ v.banner.constructor.name ]);
        };
        var backButton = Data_Monoid.guard(React_Basic.monoidJSX)(v.scene.index > 0 || opts.persistBack)(React_Basic_Native_Generated.touchableOpacity()({
            style: sheet.backButton,
            onPress: React_Basic_Events.handler_(v.onAction(ReactNavigation_Types.NavBarBack.value)),
            hitSlop: ReactNative_Touchables.insets({
                top: 10,
                bottom: 10,
                left: 10,
                right: 10
            }),
            children: [ ZAP_Dart_Client_Components_Icon.icon({
                name: "left",
                size: 36,
                color: ZAP_Dart_Client_Styles_Colors.primary
            }) ]
        }));
        return ZAP_Dart_Client_Contexts_Global.globalConsumer(function (v1) {
            return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic.keyed("element")(React_Basic_Native_Generated.view()({
                style: sheet.background,
                children: [ React_Basic_Native_Generated.safeAreaView()({
                    style: sheet.shadow,
                    children: [ ZAP_Dart_Client_Components_PrivacyLinks.privacyLinks({}), React_Basic_Native_Generated.view()({
                        style: sheet.container,
                        children: [ backButton, logo, opts.right(v) ]
                    }), banner(Data_Nullable.toMaybe(v1.totalPoints)) ]
                }), ReactNative_StatusBar.statusBar()({
                    backgroundColor: privacyLinksBg,
                    barStyle: ReactNative_StatusBar.darkContent
                }) ]
            })));
        });
    };
    return React_Basic.makeStateless(component)(render);
};
module.exports = {
    baseNavBar: baseNavBar
};
