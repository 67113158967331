// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_PropTypes_Color = require("../React.Basic.Native.PropTypes.Color/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNative_Dimensions = require("../ReactNative.Dimensions/index.js");
var ReactNative_Platform = require("../ReactNative.Platform/index.js");
var ReactNative_Touchables = require("../ReactNative.Touchables/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var ZAP_Dart_Client_Components_Icon = require("../ZAP.Dart.Client.Components.Icon/index.js");
var ZAP_Dart_Client_Components_QrScanner = require("../ZAP.Dart.Client.Components.QrScanner/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var ZAP_Dart_Client_Utilities_DeviceInfo = require("../ZAP.Dart.Client.Utilities.DeviceInfo/index.js");
var ZAP_Dart_Client_Utilities_Permissions = require("../ZAP.Dart.Client.Utilities.Permissions/index.js");
var ZAP_Dart_Common = require("../ZAP.Dart.Common/index.js");
var sheet = {
    enableCameraBtn: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.borderRadius(8), React_Basic_Native_Styles.backgroundColor(ZAP_Dart_Client_Styles_Colors.bg), React_Basic_Native_Styles.margin(React_Basic_Native_Styles.intMargin)(24), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.paddingVertical(React_Basic_Native_Styles.intPaddingVertical)(16), React_Basic_Native_Styles.paddingHorizontal(React_Basic_Native_Styles.intPaddingHorizontal)(24), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(230), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(230) ]),
    container: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.black) ]),
    rectangle: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    rectangleBorder: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.height(React_Basic_Native_Styles.percentHeight)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(100)), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.top(React_Basic_Native_Styles.intTop)(0), React_Basic_Native_Styles.left(React_Basic_Native_Styles.intLeft)(0), React_Basic_Native_Styles.borderColor(React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.2)), React_Basic_Native_Styles.borderWidth(4) ]),
    corner: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(47), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(47), React_Basic_Native_Styles.borderStyle(React_Basic_Native_Styles.solid), React_Basic_Native_Styles.borderColor(ZAP_Dart_Client_Styles_Colors.primary) ]),
    top: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.top(React_Basic_Native_Styles.intTop)(0), React_Basic_Native_Styles.borderTopWidth(6) ]),
    bottom: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.bottom(React_Basic_Native_Styles.intBottom)(0), React_Basic_Native_Styles.borderBottomWidth(6) ]),
    left: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.left(React_Basic_Native_Styles.intLeft)(0), React_Basic_Native_Styles.borderLeftWidth(6) ]),
    right: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.right(React_Basic_Native_Styles.intRight)(0), React_Basic_Native_Styles.borderRightWidth(6) ]),
    bottomTextContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(38), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(50) ]),
    overlayContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.transparent), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    rectangleWrapper: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.flexDirection(React_Basic_Native_Styles_Flex.row) ]),
    overlay: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.backgroundColor(React_Basic_Native_PropTypes_Color.rgba(0)(0)(0)(0.2)) ]),
    overlayTop: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(82), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_Styles_Flex.flexEnd(React_Basic_Native_Styles_Flex.justifyContentFlexSE)) ]),
    overlayLeft: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(28) ]),
    overlayRight: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(28) ]),
    enableCameraTxt: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)) ]),
    enterCodeButtonLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(12), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(1) ]),
    enterCodeButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.extraBold), React_Basic_Native_Styles_Text.textAlign(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Text.centerTextAlign)), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.intLetterSpacing)(1) ]),
    exitButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute), React_Basic_Native_Styles.top(React_Basic_Native_Styles.intTop)(37), React_Basic_Native_Styles.left(React_Basic_Native_Styles.intLeft)(28) ])
};
var component = React_Basic.createComponent("ScanQr");
var scanQr = function (info) {
    var onEnableCamera = function (self) {
        if (ReactNative_Platform.os instanceof ReactNative_Platform.IOS && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(self.state.cameraPermission)(new Data_Maybe.Just("denied"))) {
            return Effect_Aff.launchAff_(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(ZAP_Dart_Client_Utilities_Permissions.openAppSettings)(function () {
                return Control_Bind.bind(Effect_Aff.bindAff)(ZAP_Dart_Client_Utilities_Permissions.checkCameraPermission)(function (v) {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(self.setState(function (v1) {
                        return {
                            cameraPermission: new Data_Maybe.Just(v.status),
                            isCameraAvailable: v1.isCameraAvailable,
                            allowSubmit: v1.allowSubmit,
                            navUnsubs: v1.navUnsubs,
                            showQrScanner: v1.showQrScanner
                        };
                    }));
                });
            }));
        };
        if (ReactNative_Platform.os instanceof ReactNative_Platform.Android && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(self.state.cameraPermission)(new Data_Maybe.Just("denied"))) {
            return self.props.showDialog(new ReactNavigation_Types.Confirm({
                message: "Access to the camera has been disabled. To scan codes, enable it in your device's settings.",
                title: "Enable Camera in Settings",
                primaryActionLabel: "GO TO SETTINGS",
                secondaryActionLabel: Data_Maybe.Nothing.value
            }))(Effect_Aff.launchAff_(ZAP_Dart_Client_Utilities_Permissions.openAppSettings));
        };
        return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(ZAP_Dart_Client_Utilities_Permissions.requestCameraPermission)(function (v) {
            return Control_Bind.bind(Effect_Aff.bindAff)(ZAP_Dart_Client_Utilities_DeviceInfo.hasVideoInputDevice)(function (hv) {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(self.setState(function (v1) {
                    return {
                        cameraPermission: new Data_Maybe.Just(v.status),
                        isCameraAvailable: hv,
                        allowSubmit: v1.allowSubmit,
                        navUnsubs: v1.navUnsubs,
                        showQrScanner: v1.showQrScanner
                    };
                }));
            });
        }));
    };
    var renderEnableCameraButton = function (self) {
        if (self.state.cameraPermission instanceof Data_Maybe.Just && self.state.cameraPermission.value0 === "granted") {
            return Data_Monoid.mempty(React_Basic.monoidJSX);
        };
        if (self.state.cameraPermission instanceof Data_Maybe.Just) {
            return React_Basic_Native_Generated.touchableOpacity()({
                style: sheet.enableCameraBtn,
                onPress: React_Basic_Events.handler_(onEnableCamera(self)),
                disabled: Data_Eq.eq(ReactNative_Platform.eqPlatform)(ReactNative_Platform.os)(ReactNative_Platform.Web.value),
                children: [ ZAP_Dart_Client_Components_Icon.icon({
                    name: "camera",
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    size: 105
                }), ZAP_Dart_Client_Components_Typography.b1({
                    style: sheet.enableCameraTxt,
                    value: (function () {
                        if (self.state.cameraPermission instanceof Data_Maybe.Just && self.state.cameraPermission.value0 === "denied") {
                            if (Data_Eq.eq(ReactNative_Platform.eqPlatform)(ReactNative_Platform.os)(ReactNative_Platform.Web.value)) {
                                return "Enable camera permission in your browser";
                            };
                            if (Data_Eq.eq(ReactNative_Platform.eqPlatform)(ReactNative_Platform.os)(ReactNative_Platform.IOS.value)) {
                                return "Camera is unavailable";
                            };
                        };
                        return "Enable Camera to scan QR codes and earn points";
                    })(),
                    color: ZAP_Dart_Client_Styles_Colors.altFg
                }) ]
            });
        };
        if (self.state.cameraPermission instanceof Data_Maybe.Nothing) {
            return Data_Monoid.mempty(React_Basic.monoidJSX);
        };
        throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.ScanQr (line 123, column 35 - line 148, column 22): " + [ self.state.cameraPermission.constructor.name ]);
    };
    var onAcquireFocus = function (v) {
        return function (v1) {
            var isFocus = v.props.focusState.isFocusing || v.props.focusState.isFocused;
            var focusHasChanged = v.props.focusState.isFocusing !== v1.prevProps.focusState.isFocusing || v.props.focusState.isFocused !== v1.prevProps.focusState.isFocused;
            return Control_Applicative.when(Effect.applicativeEffect)(isFocus && focusHasChanged)(v.setState(function (v2) {
                var $27 = {};
                for (var $28 in v2) {
                    if ({}.hasOwnProperty.call(v2, $28)) {
                        $27[$28] = v2[$28];
                    };
                };
                $27.allowSubmit = true;
                return $27;
            }));
        };
    };
    var initialState = {
        showQrScanner: false,
        navUnsubs: [  ],
        cameraPermission: Data_Maybe.Nothing.value,
        isCameraAvailable: false,
        allowSubmit: true
    };
    var handleScan = function (self) {
        return function (d) {
            var v = self.state.allowSubmit && self.props.focusState.isFocused;
            if (v) {
                return function __do() {
                    self.setState(function (v1) {
                        return {
                            showQrScanner: v1.showQrScanner,
                            navUnsubs: v1.navUnsubs,
                            cameraPermission: v1.cameraPermission,
                            isCameraAvailable: v1.isCameraAvailable,
                            allowSubmit: false
                        };
                    })();
                    return self.props.onAction(new ReactNavigation_Types.PgSubmit(new ZAP_Dart_Client_Screens_Types.SQVal({
                        claimCode: new ZAP_Dart_Common.QRCode({
                            val: d
                        }),
                        releaseHook: self.setState(function (v1) {
                            return {
                                showQrScanner: v1.showQrScanner,
                                navUnsubs: v1.navUnsubs,
                                cameraPermission: v1.cameraPermission,
                                isCameraAvailable: v1.isCameraAvailable,
                                allowSubmit: true
                            };
                        })
                    })))();
                };
            };
            if (!v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.ScanQr (line 151, column 23 - line 155, column 23): " + [ v.constructor.name ]);
        };
    };
    var handleError = function (self) {
        return function __do() {
            self.setState(function (v) {
                return {
                    showQrScanner: v.showQrScanner,
                    navUnsubs: v.navUnsubs,
                    cameraPermission: v.cameraPermission,
                    isCameraAvailable: v.isCameraAvailable,
                    allowSubmit: true
                };
            })();
            return Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.alert("Cannot Load QR Scanner"))();
        };
    };
    var render = function (v) {
        var showQrScanner = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.state.cameraPermission)(new Data_Maybe.Just("granted")) && (v.state.isCameraAvailable && v.props.focusState.isFocused);
        var ratio = 4.0 / 3.0;
        var cameraWidth = (function () {
            if (ReactNative_Platform.os instanceof ReactNative_Platform.Android) {
                return Data_Int.toNumber(ReactNative_Dimensions.getWindow.height) / ratio;
            };
            return Data_Int.toNumber(ReactNative_Dimensions.getWindow.width);
        })();
        return React_Basic_Native_Generated.view()({
            style: sheet.container,
            children: [ Data_Monoid.guard(React_Basic.monoidJSX)(showQrScanner)(ZAP_Dart_Client_Components_QrScanner.qrScanner({
                style: React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.flex(1), React_Basic_Native_Styles_Flex.justifyContent(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.jcCenter)), React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.numberWidth)(cameraWidth), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(ReactNative_Dimensions.getWindow.height), React_Basic_Native_Styles.position(React_Basic_Native_Styles.absolute) ]),
                onScan: function (s) {
                    if (v.props.focusState.isFocused) {
                        return handleScan(v)(s);
                    };
                    if (!v.props.focusState.isFocused) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Screens.ScanQr (line 189, column 27 - line 191, column 41): " + [ v.props.focusState.isFocused.constructor.name ]);
                },
                onError: function (v1) {
                    return handleError(v);
                },
                delay: 1200
            })), React_Basic_Native_Generated.view()({
                style: React_Basic_Native_Styles["styles'"]([ sheet.overlayContainer, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(ReactNative_Dimensions.getWindow.width), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(ReactNative_Dimensions.getWindow.height) ]) ]),
                children: [ React_Basic_Native_Generated.view()({
                    style: React_Basic_Native_Styles["styles'"]([ sheet.overlay, sheet.overlayTop, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(ReactNative_Dimensions.getWindow.width) ]) ])
                }), React_Basic_Native_Generated.view()({
                    style: React_Basic_Native_Styles["styles'"]([ sheet.rectangleWrapper, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(ReactNative_Dimensions.getWindow.width) ]) ]),
                    children: [ React_Basic_Native_Generated.view()({
                        style: React_Basic_Native_Styles["styles'"]([ sheet.overlay, sheet.overlayLeft ])
                    }), React_Basic_Native_Generated.view()({
                        style: sheet.rectangle,
                        children: [ React_Basic_Native_Generated.view()({
                            style: sheet.rectangleBorder
                        }), React_Basic_Native_Generated.view()({
                            style: React_Basic_Native_Styles["styles'"]([ sheet.corner, sheet.top, sheet.left ])
                        }), React_Basic_Native_Generated.view()({
                            style: React_Basic_Native_Styles["styles'"]([ sheet.corner, sheet.top, sheet.right ])
                        }), React_Basic_Native_Generated.view()({
                            style: React_Basic_Native_Styles["styles'"]([ sheet.corner, sheet.bottom, sheet.left ])
                        }), React_Basic_Native_Generated.view()({
                            style: React_Basic_Native_Styles["styles'"]([ sheet.corner, sheet.bottom, sheet.right ])
                        }), renderEnableCameraButton(v) ]
                    }), React_Basic_Native_Generated.view()({
                        style: React_Basic_Native_Styles["styles'"]([ sheet.overlay, sheet.overlayRight ])
                    }) ]
                }), React_Basic_Native_Generated.view()({
                    style: React_Basic_Native_Styles["styles'"]([ sheet.overlay, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(ReactNative_Dimensions.getWindow.width) ]) ]),
                    children: [ React_Basic_Native_Generated.view()({
                        style: sheet.bottomTextContainer,
                        children: [ ZAP_Dart_Client_Components_Typography.h4({
                            style: sheet.enterCodeButtonLabel,
                            color: ZAP_Dart_Client_Styles_Colors.bg,
                            value: "Can't scan?"
                        }), React_Basic_Native_Generated.touchableOpacity()({
                            onPress: React_Basic_Events.handler_(v.props.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.SQGoToEnterShortCodeScreen.value))),
                            children: [ ZAP_Dart_Client_Components_Typography.h4({
                                style: sheet.enterCodeButton,
                                color: ZAP_Dart_Client_Styles_Colors.bg,
                                value: "ENTER CODE MANUALLY"
                            }) ]
                        }) ]
                    }) ]
                }), React_Basic_Native_Generated.touchableOpacity()({
                    style: sheet.exitButton,
                    onPress: React_Basic_Events.handler_(v.props.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.SQClose.value))),
                    hitSlop: ReactNative_Touchables.insets({
                        top: 10,
                        bottom: 10,
                        left: 10,
                        right: 10
                    }),
                    children: [ ZAP_Dart_Client_Components_Icon.icon({
                        name: "exit_outlined",
                        size: 32,
                        color: ZAP_Dart_Client_Styles_Colors.bg
                    }) ]
                }) ]
            }) ]
        });
    };
    var didUpdate = function (self) {
        return function (prevSelf) {
            return onAcquireFocus(self)(prevSelf);
        };
    };
    var cleanup = function (state) {
        return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(state.navUnsubs)(Control_Category.identity(Control_Category.categoryFn));
    };
    var willUnmount = function (self) {
        return cleanup(self.state);
    };
    var checkCameraPermission = function (self) {
        return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(ZAP_Dart_Client_Utilities_Permissions.checkCameraPermission)(function (v) {
            return Control_Bind.bind(Effect_Aff.bindAff)(ZAP_Dart_Client_Utilities_DeviceInfo.hasVideoInputDevice)(function (hv) {
                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(self.setState(function (v1) {
                    var $41 = {};
                    for (var $42 in v1) {
                        if ({}.hasOwnProperty.call(v1, $42)) {
                            $41[$42] = v1[$42];
                        };
                    };
                    $41.cameraPermission = new Data_Maybe.Just(v.status);
                    $41.isCameraAvailable = hv;
                    return $41;
                }));
            });
        }));
    };
    var didMount = function (v) {
        return checkCameraPermission(v);
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render,
        didMount: didMount,
        didUpdate: didUpdate,
        willUnmount: willUnmount
    });
};
module.exports = {
    scanQr: scanQr
};
