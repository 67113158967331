// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Assets = require("../ZAP.Dart.Client.Assets/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_InputWithIconButton = require("../ZAP.Dart.Client.Components.InputWithIconButton/index.js");
var ZAP_Dart_Client_Screens_Templates_GuestStepWrapper = require("../ZAP.Dart.Client.Screens.Templates.GuestStepWrapper/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    errorMsg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(18) ]),
    enterCodeImage: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.width(React_Basic_Native_Styles.intWidth)(121), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(137), React_Basic_Native_Styles.marginVertical(React_Basic_Native_Styles.intMarginVertical)(40) ]),
    submitButton: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.maxWidth(React_Basic_Native_Styles.intMaxWidth)(360), React_Basic_Native_Styles_Flex.alignSelf(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)), React_Basic_Native_Styles.width(React_Basic_Native_Styles.percentWidth)(React_Basic_Native_Styles.percent(React_Basic_Native_Styles.percentInt)(80)), React_Basic_Native_Styles.marginBottom(React_Basic_Native_Styles.intMarginBottom)(40) ])
};
var component = React_Basic.createComponent("EnterShortCode");
var enterShortCode = (function () {
    var shouldSubmit = function (self) {
        return Data_String_CodePoints.length(self.state.shortCode) > 0;
    };
    var onSubmit = function (self) {
        return function __do() {
            self.setState(function (v) {
                return {
                    shortCode: ""
                };
            })();
            return Control_Applicative.when(Effect.applicativeEffect)(shouldSubmit(self))(self.props.onAction(new ReactNavigation_Types.PgSubmit(new ZAP_Dart_Client_Screens_Types.SCIMManual(self.state.shortCode))))();
        };
    };
    var onCancel = function (self) {
        return self.props.onAction(new ReactNavigation_Types.PgSubmit(ZAP_Dart_Client_Screens_Types.SCIMScanQr.value));
    };
    var render = function (self) {
        return ZAP_Dart_Client_Screens_Templates_GuestStepWrapper.guestStepWrapper({
            header: new Data_Tuple.Tuple("Input the code found on", "the packaging"),
            message: new Data_Tuple.Tuple("or scan using your device's camera", ""),
            content: [ ZAP_Dart_Client_Components_InputWithIconButton.inputWithIconButton({
                value: self.state.shortCode,
                onChange: function (text) {
                    return self.setState(function (v) {
                        return {
                            shortCode: text
                        };
                    });
                },
                inputType: ZAP_Dart_Client_Components_InputWithIconButton.AlphaNumeric.value,
                onSubmitEditing: onSubmit(self),
                onIconPress: onCancel(self),
                secureTextEntry: false,
                autoFocus: true,
                containerHeight: 45,
                containerWidth: 271,
                inputWidth: 223,
                fontSize: 24,
                letterSpacing: 2,
                placeholderText: "Enter code",
                iconName: "camera-outline",
                iconSize: 30
            }), React_Basic_Native_Generated.image()({
                source: ZAP_Dart_Client_Assets.enterCode,
                style: sheet.enterCodeImage
            }), ZAP_Dart_Client_Components_Button.primaryButton({
                style: sheet.submitButton,
                disabled: !shouldSubmit(self),
                inverted: false,
                size: ZAP_Dart_Client_Components_Button.lg,
                onPress: onSubmit(self),
                title: "SUBMIT"
            }) ]
        });
    };
    var initialState = {
        shortCode: ""
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
})();
module.exports = {
    enterShortCode: enterShortCode
};
