// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Native_PropTypes = require("../React.Basic.Native.PropTypes/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Flex = require("../React.Basic.Native.Styles.Flex/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ReactNavigation_Types = require("../ReactNavigation.Types/index.js");
var ZAP_Dart_Client_Components_Button = require("../ZAP.Dart.Client.Components.Button/index.js");
var ZAP_Dart_Client_Components_DashedInput = require("../ZAP.Dart.Client.Components.DashedInput/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Screens_Templates_StepWrapper = require("../ZAP.Dart.Client.Screens.Templates.StepWrapper/index.js");
var ZAP_Dart_Client_Screens_Types = require("../ZAP.Dart.Client.Screens.Types/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    input: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Flex.alignItems(React_Basic_Native_PropTypes.center(React_Basic_Native_Styles_Flex.flexAlignmentCenter)) ]),
    resetPinLabel: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(64) ]),
    resetPinBtn: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(4) ]),
    errorMsg: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles.marginTop(React_Basic_Native_Styles.intMarginTop)(24), React_Basic_Native_Styles.height(React_Basic_Native_Styles.intHeight)(18) ])
};
var component = React_Basic.createComponent("EnterPin");
var enterPinF = function (v) {
    var submit = function (self) {
        return function (pin) {
            return Control_Applicative.when(Effect.applicativeEffect)(Data_String_CodeUnits.length(pin) === 6)(self.props.onAction(new ReactNavigation_Types.PgSubmit(new ZAP_Dart_Client_Screens_Types.EPaPin(pin))));
        };
    };
    var onSubmit = function (self) {
        return React_Basic_Events.handler_(submit(self)(self.state.pin));
    };
    var render = function (self) {
        return ZAP_Dart_Client_Screens_Templates_StepWrapper.stepWrapper({
            header: new Data_Tuple.Tuple("ENTER YOUR", "6-DIGIT PIN"),
            message: new Data_Tuple.Tuple("Welcome back!", ""),
            content: [ ZAP_Dart_Client_Components_DashedInput.dashedInput({
                value: self.state.pin,
                length: 6,
                fontSize: 32,
                letterSpacing: 8,
                onChange: function (text) {
                    return Control_Apply.applySecond(Effect.applyEffect)(self.setState(function (v1) {
                        return {
                            pin: text,
                            error: Data_Maybe.Nothing.value
                        };
                    }))(submit(self)(text));
                },
                inputType: ZAP_Dart_Client_Components_DashedInput.Numeric.value,
                onSubmitEditing: onSubmit(self),
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.input,
                secureTextEntry: true,
                autoFocus: true
            }), ZAP_Dart_Client_Components_Typography.b2({
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.errorMsg,
                value: Data_Maybe.fromMaybe("")(self.state.error)
            }), ZAP_Dart_Client_Components_Typography.b2({
                color: ZAP_Dart_Client_Styles_Colors.altBg,
                style: sheet.resetPinLabel,
                value: "Forgot your PIN?"
            }), ZAP_Dart_Client_Components_Button.linkButton({
                onPress: self.props.onAction(ReactNavigation_Types.PgSubmit.create(ZAP_Dart_Client_Screens_Types.EPaResetPin.value)),
                title: "Reset PIN",
                size: ZAP_Dart_Client_Components_Button.sm,
                style: sheet.resetPinBtn,
                disabled: false
            }) ]
        });
    };
    var initialState = {
        pin: "",
        error: v.error
    };
    return React_Basic.make()(component)({
        initialState: initialState,
        render: render
    });
};
module.exports = {
    enterPinF: enterPinF
};
