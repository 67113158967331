// Generated by purs version 0.13.8
"use strict";
var React_Basic_Native = require("../React.Basic.Native/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var H1 = (function () {
    function H1() {

    };
    H1.value = new H1();
    return H1;
})();
var H2 = (function () {
    function H2() {

    };
    H2.value = new H2();
    return H2;
})();
var H3 = (function () {
    function H3() {

    };
    H3.value = new H3();
    return H3;
})();
var H4 = (function () {
    function H4() {

    };
    H4.value = new H4();
    return H4;
})();
var H5 = (function () {
    function H5() {

    };
    H5.value = new H5();
    return H5;
})();
var B1 = (function () {
    function B1() {

    };
    B1.value = new B1();
    return B1;
})();
var B2 = (function () {
    function B2() {

    };
    B2.value = new B2();
    return B2;
})();
var B3 = (function () {
    function B3() {

    };
    B3.value = new B3();
    return B3;
})();
var B4 = (function () {
    function B4() {

    };
    B4.value = new B4();
    return B4;
})();
var B5 = (function () {
    function B5() {

    };
    B5.value = new B5();
    return B5;
})();
var sheet = {
    h: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.semiBold), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.altFg) ]),
    b: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.regular), React_Basic_Native_Styles_Text.color(ZAP_Dart_Client_Styles_Colors.altFg) ]),
    h1: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(36), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.9) ]),
    h2: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(28), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.7) ]),
    h3: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(24), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.6) ]),
    h4: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(20), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.5) ]),
    h5: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(18), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.45) ]),
    b1: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(16), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.8) ]),
    b2: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(14), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.7) ]),
    b3: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(12), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.6) ]),
    b4: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(10), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.5) ]),
    b5: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontSize(8), React_Basic_Native_Styles_Text.letterSpacing(React_Basic_Native_Styles_Text.numberLetterSpacing)(0.4) ])
};
var getStyleFromType = function (v) {
    if (v instanceof H1) {
        return React_Basic_Native_Styles["styles'"]([ sheet.h, sheet.h1 ]);
    };
    if (v instanceof H2) {
        return React_Basic_Native_Styles["styles'"]([ sheet.h, sheet.h2 ]);
    };
    if (v instanceof H3) {
        return React_Basic_Native_Styles["styles'"]([ sheet.h, sheet.h3 ]);
    };
    if (v instanceof H4) {
        return React_Basic_Native_Styles["styles'"]([ sheet.h, sheet.h4 ]);
    };
    if (v instanceof H5) {
        return React_Basic_Native_Styles["styles'"]([ sheet.h, sheet.h5 ]);
    };
    if (v instanceof B1) {
        return React_Basic_Native_Styles["styles'"]([ sheet.b, sheet.b1 ]);
    };
    if (v instanceof B2) {
        return React_Basic_Native_Styles["styles'"]([ sheet.b, sheet.b2 ]);
    };
    if (v instanceof B3) {
        return React_Basic_Native_Styles["styles'"]([ sheet.b, sheet.b3 ]);
    };
    if (v instanceof B4) {
        return React_Basic_Native_Styles["styles'"]([ sheet.b, sheet.b4 ]);
    };
    if (v instanceof B5) {
        return React_Basic_Native_Styles["styles'"]([ sheet.b, sheet.b5 ]);
    };
    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Typography (line 44, column 20 - line 54, column 38): " + [ v.constructor.name ]);
};
var text = function (props) {
    return React_Basic_Native_Generated.text()({
        style: React_Basic_Native_Styles["styles'"]([ getStyleFromType(props.type), props.style, React_Basic_Native_Styles.styles([ React_Basic_Native_Styles_Text.color(props.color) ]) ]),
        children: props.children
    });
};
var text$prime = function (t) {
    return function (props) {
        return text({
            type: t,
            style: props.style,
            color: props.color,
            children: [ React_Basic_Native.string(props.value) ]
        });
    };
};
var h1 = text$prime(H1.value);
var h2 = text$prime(H2.value);
var h3 = text$prime(H3.value);
var h4 = text$prime(H4.value);
var h5 = text$prime(H5.value);
var b5 = text$prime(B5.value);
var b4 = text$prime(B4.value);
var b3 = text$prime(B3.value);
var b2 = text$prime(B2.value);
var b1 = text$prime(B1.value);
module.exports = {
    H1: H1,
    H2: H2,
    H3: H3,
    H4: H4,
    H5: H5,
    B1: B1,
    B2: B2,
    B3: B3,
    B4: B4,
    B5: B5,
    text: text,
    h1: h1,
    h2: h2,
    h4: h4,
    h3: h3,
    h5: h5,
    b1: b1,
    b2: b2,
    b3: b3,
    b4: b4,
    b5: b5
};
