// Generated by purs version 0.13.8
"use strict";
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Native_Generated = require("../React.Basic.Native.Generated/index.js");
var React_Basic_Native_Styles = require("../React.Basic.Native.Styles/index.js");
var React_Basic_Native_Styles_Text = require("../React.Basic.Native.Styles.Text/index.js");
var ZAP_Dart_Client_Components_Typography = require("../ZAP.Dart.Client.Components.Typography/index.js");
var ZAP_Dart_Client_Styles_Colors = require("../ZAP.Dart.Client.Styles.Colors/index.js");
var ZAP_Dart_Client_Styles_Fonts = require("../ZAP.Dart.Client.Styles.Fonts/index.js");
var sheet = {
    labelContainer: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles.paddingBottom(React_Basic_Native_Styles.intPaddingBottom)(10) ]),
    label: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.extraBold) ]),
    optionalTag: React_Basic_Native_Styles.staticStyles([ React_Basic_Native_Styles_Text.fontFamily(ZAP_Dart_Client_Styles_Fonts.proximaNova.italic) ])
};
var component = React_Basic.createComponent("InputGroup");
var inputGroup = (function () {
    var render = function (v) {
        return React_Basic_Native_Generated.view()({
            style: v.style,
            children: [ ZAP_Dart_Client_Components_Typography.text({
                style: sheet.labelContainer,
                type: ZAP_Dart_Client_Components_Typography.B3.value,
                color: ZAP_Dart_Client_Styles_Colors.altFg,
                children: [ ZAP_Dart_Client_Components_Typography.b3({
                    style: sheet.label,
                    color: ZAP_Dart_Client_Styles_Colors.altFg,
                    value: v.label
                }), (function () {
                    if (v.isRequired) {
                        return Data_Monoid.mempty(React_Basic.monoidJSX);
                    };
                    if (!v.isRequired) {
                        return ZAP_Dart_Client_Components_Typography.b3({
                            style: sheet.optionalTag,
                            color: ZAP_Dart_Client_Styles_Colors.altFg,
                            value: " (optional)"
                        });
                    };
                    throw new Error("Failed pattern match at ZAP.Dart.Client.Components.Input.InputGroup (line 43, column 15 - line 49, column 27): " + [ v.isRequired.constructor.name ]);
                })() ]
            }), v.input ]
        });
    };
    return React_Basic.makeStateless(component)(render);
})();
module.exports = {
    inputGroup: inputGroup
};
